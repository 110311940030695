$(function () {
  // ピックアップ商品 スライダー
  $('.js-slider').slick({
    arrows: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 1000,
    centerMode: true,
    centerPadding: '20%',
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    appendArrows: $('.p-top_pickup_content_arrow'),
    prevArrow: "<span class='prev'></span>",
    nextArrow: "<span class='next'></span>",
  })

  // おすすめモーダルオープン
  $('#open-recommend-modal').on('click', function () {
    $('#recommend-modal').addClass('active')
  })

  // モーダルクローズ
  $('.modal-close').on('click', function () {
    $('.c-modal').removeClass('active')
  })

  $('.c-modal').on('click', function (e) {
    if ($(e.target).find('.c-modal_recommend_content').length) {
      $('.c-modal').removeClass('active')
    }
  })
})
